var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{attrs:{"name":"contactsOverview","loading":_vm.isTableLoading,"items":_vm.contacts,"items-length":_vm.itemsLength,"headers":_vm.headers,"clickable":true,"has-add-button":true,"add-button-text":_vm.$tc('newContact', 1),"itemsPerPageOptions":_vm.itemsPerPageOptions},on:{"optionsChanged":_vm.onTableQueryChange,"rowClicked":_vm.handleOnRowClick,"addItemClicked":function($event){return _vm.openDialog('createContact')}},scopedSlots:_vm._u([{key:"export",fn:function(){return [_c('v-btn',{attrs:{"dark":"","color":"primary"},on:{"click":function($event){return _vm.openDialog('export')}}},[_vm._v("Export ")])]},proxy:true},{key:"top-right",fn:function(){return [_c('v-checkbox',{staticClass:"align-center justify-center",attrs:{"hide-details":"","label":_vm.$t('showAlsoDisabled')},model:{value:(_vm.showDisabledContacts),callback:function ($$v) {_vm.showDisabledContacts=$$v},expression:"showDisabledContacts"}})]},proxy:true},{key:"item.rating",fn:function(){return [_vm._v("N/A")]},proxy:true},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "),(item.isDisabled)?_c('v-chip',{staticClass:"text-uppercase ml-2",attrs:{"small":"","text-color":"white","color":"black"},domProps:{"textContent":_vm._s(_vm.$tc('deactivated'))}}):_vm._e()]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastName)+" ")]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [(item.address)?[_vm._v(_vm._s(("" + (item.address.city))))]:_vm._e()]}},{key:"item.mail",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('Link',{attrs:{"href":item.email,"prefix":_vm.LinkType.MAIL}}):_vm._e()]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.company.id)?_c('router-link',{attrs:{"to":_vm.companyDetailLink(item.company)}},[_vm._v(" "+_vm._s(item.company.name)+" ")]):_c('p')],1)]}},{key:"item.phoneMobile",fn:function(ref){
var item = ref.item;
return [(item.phoneMobile)?_c('Link',{attrs:{"href":item.phoneMobile,"prefix":_vm.LinkType.TELEPHONE}}):_vm._e()]}},{key:"item.phoneFixed",fn:function(ref){
var item = ref.item;
return [(item.phoneFixed)?_c('Link',{attrs:{"href":item.phoneFixed,"prefix":_vm.LinkType.TELEPHONE}}):_vm._e()]}},{key:"item.hasProject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("booleanTextValue")(item.hasProject))+" ")]}},{key:"item.hasSearchProfile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("booleanTextValue")(item.hasSearchProfile))+" ")]}},{key:"item.ndaSigned",fn:function(ref){
var item = ref.item;
return [_c('NdaSignedCell',{attrs:{"contact":item},on:{"nda-approved-changed":_vm.fetchContacts,"nda-uploaded":_vm.fetchContacts}})]}},{key:"item.ndaSent",fn:function(ref){
var item = ref.item;
return [_c('NdaSentCell',{attrs:{"contact":item},on:{"nda-sent":_vm.fetchContacts}})]}}])}),_c('DialogWrapper',{attrs:{"value":_vm.isDialogOpened},on:{"close":_vm.closeDialog}},[(_vm.activeDialog === 'createContact')?_c('ContactFormWrapper',{attrs:{"entity-id":_vm.dialogEntityId,"contact-type-id":_vm.contactFormType},on:{"cancel":_vm.closeDialog,"success":_vm.onContactCreatedSuccess}}):_vm._e(),(_vm.activeDialog === 'export')?_c('ContactTableExport',{attrs:{"entity-id":_vm.dialogEntityId,"submit-form":_vm.handleExport},on:{"cancel":_vm.closeDialog,"exported":_vm.closeDialog}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }