





























































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import DataTable from "@/components/data-table/DataTable.vue";
import { ITableHeader, TableQuery } from "@/components/data-table/types";
import Contact, { ContactTypeId, getContactFullName } from "@/entity/Contact";
import ContactsService from "@/services/ContactsService";
import DialogMixin from "@/mixins/DialogMixin";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import Link from "@/components/common/Link.vue";
import { LinkType } from "@/enums";
import { State } from "vuex-class";
import contactDetailNavigationTo from "@/utils/contactDetailNavigateTo";
import { ReferenceItem } from "@/services/types";
import ContactFormWrapper from "@/components/ContactFormWrapper.vue";
import NdaSignedCell from "@/components/contact/NdaSignedCell.vue";
import NdaSentCell from "@/components/contact/NdaSentCell.vue";
import companyDetailNavigationTo from "@/utils/companyDetailNavigateTo";
import * as XLSX from "xlsx";
import ContactTableExport from "@/components/ContactTableExport.vue";
import Company from "@/entity/Company";

@Component({
  components: {
    DataTable,
    ContactFormWrapper,
    Link,
    NdaSignedCell,
    NdaSentCell,
    ContactTableExport,
  },
})
export default class ContactsView extends Mixins(
  DialogMixin,
  TranslatedEnumListsMixin
) {
  isTableLoading = false;
  itemsLength = 0;
  contacts: Contact[] = [];
  tableQuery: TableQuery = {};
  itemsPerPageOptions = [10, 50, 200, 400];
  showDisabledContacts = false;
  companyDetailLink = companyDetailNavigationTo;

  contactFullName = getContactFullName;
  contactFormType = ContactTypeId.contact;
  LinkType = LinkType;

  @State("buyerTypes", { namespace: "selectOptions" })
  buyerTypes!: ReferenceItem[];

  get headers(): ITableHeader[] {
    return [
      {
        filterType: "text",
        text: this.$tc("name", 1),
        value: "firstName",
        width: "200px",
      },
      {
        filterType: "text",
        text: this.$tc("firstName", 1),
        value: "lastName",
        width: "200px",
      },
      {
        filterType: "text",
        text: this.$tc("city", 1),
        value: "city",
        width: "150px",
      },
      {
        filterType: "text",
        text: this.$tc("email", 1),
        value: "mail",
        width: "200px",
      },
      {
        text: this.$tc("companyName", 1),
        value: "company",
        filterType: "text",
        width: "200px",
      },
      {
        filterType: "text",
        text: this.$tc("phoneMobile", 1),
        value: "phoneMobile",
        width: "150px",
      },
      {
        filterType: "text",
        text: this.$tc("phoneFixed", 1),
        value: "phoneFixed",
        width: "150px",
      },
      {
        filterType: "select",
        options: this.translatedEnums.boolOptions,
        optionValue: "value",
        optionText: "name",
        text: this.$tc("interested", 1),
        value: "hasProject",
        width: "150px",
      },
      {
        text: this.$tc("buyerType", 1),
        value: "buyerType.name",
        filterValue: "buyerTypeId",
        sortable: true,
        options: this.buyerTypes,
        optionText: "name",
        optionValue: "id",
        filterType: "select",
        width: "150px",
      },
      {
        filterType: "select",
        options: this.translatedEnums.boolOptions,
        optionValue: "value",
        optionText: "name",
        text: this.$tc("hasSearchProfile", 1),
        value: "hasSearchProfile",
        width: "150px",
      },
      {
        text: this.$tc("ndaSigned"),
        value: "ndaSigned",
        sortable: true,
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        width: "150px",
      },
      {
        text: this.$tc("ndaSent"),
        value: "ndaSent",
        sortable: true,
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        width: "150px",
      },
      {
        text: this.$tc("rating", 1),
        value: "rating",
        sortable: false,
        width: "80px",
      },
    ];
  }

  get fullTableQuery() {
    return {
      ...this.tableQuery,
      filterBy: {
        ...this.tableQuery.filterBy,
        contactType: ContactTypeId.contact,
        isDisabled: this.showDisabledContacts ? undefined : false,
      },
    };
  }

  async fetchContacts() {
    try {
      this.isTableLoading = true;
      const response = await ContactsService.find(this.fullTableQuery);
      this.contacts = response.content;
      this.itemsLength = response.totalItems;
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isTableLoading = false;
    }
  }

  handleOnRowClick(contact: Contact) {
    this.$router.push({
      name: "contactDetail",
      params: {
        contactId: (contact.id as number).toString(),
      },
    });
  }

  onContactCreatedSuccess(contact: Contact) {
    const to = contactDetailNavigationTo(contact);
    if (to) {
      this.$router.push(to);
    }
  }

  onTableQueryChange(tableQuery: TableQuery) {
    this.tableQuery = tableQuery;
  }

  async handleExport(filename: string) {
    const response = await ContactsService.getDataForExport(
      // this.fullTableQuery.filterBy,
      { contactType: this.fullTableQuery.filterBy.contactType }
    );

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(
      response.map((item) => ({
        [this.$tc("firstName")]: item.lastName,
        [this.$tc("name")]: item.firstName,
        [this.$tc("email")]: item.email,
        [this.$tc("salutation")]: item.salutation,
      }))
    );

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      this.$tc("prospectiveBuyer", 1)
    );

    XLSX.writeFile(workbook, `${filename}.xlsx`, { compression: true });
    this.closeDialog();
  }

  @Watch("fullTableQuery", { deep: true })
  onFullTableQueryChange() {
    this.fetchContacts();
  }
}
