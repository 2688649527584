






















import { Component, Mixins, Prop } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import RulesMixin from "@/mixins/RulesMixin";
import BaseForm from "@/components/form/BaseForm";

@Component({
  components: {
    FormCard,
  },
})
export default class ContactTableExportCard extends Mixins(
  RulesMixin,
  BaseForm
) {
  @Prop({ required: true, type: Function }) submitForm!: (
    filename: string
  ) => any;

  fileName = "";

  onFileNameKeypress(event: KeyboardEvent): void {
    if (event.key === "Enter" && this.fileName.length > 0) {
      event.preventDefault();
      this.submitForm(this.fileName);
    }
  }
}
